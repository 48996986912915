import axios from 'axios'

export default {
    get: () => axios.get('roads'),
    store: (data) => axios.post('roads/store', data),
    update: (data) => axios.post('roads/update', data),
    delete: (id) => axios.post('roads/delete', id),
    map: (type) => axios.get('roads/'+type+'/map'),
    regionTypeMap: (region, type) => axios.get('roads/'+type+'/'+region+'/map'),
    getMaps: (type) => axios.get('roads/'+type+'/maps'),
    getStroke: (data) => axios.get('roads/'+data+'/get-stroke'),
    addStroke: (data) => axios.post('roads/add-stroke', data),
    updateStroke: (data) => axios.post('roads/update-stroke', data),
    closestSection: ($lat, $lon) => axios.get('roads/closest-section/'+$lat+'/'+$lon)
}