<template>
  <div>
    <CRow>
      <CCol sm="12">
        <div style="float: right; font-size: 12px;">
          <table>
            <tr>
              <td>
                <label>Rubro: </label>
                <select v-model="type" class="form-control" style="width: 210px; font-size: 12px; margin-top: -5px;" @change="changeType">
                  <option value="sections">General</option>
                  <option :value="rubro.code" v-for="rubro in rubros">{{rubro.name}}</option>
                </select>
              </td>
              <td>
                <label>Localidad: </label>
                <select v-model="region" class="form-control" style="width: 210px; font-size: 12px; margin-top: -5px;" @change="changeLocation">
                  <option :value="map_location.region.region" v-for="map_location in maps">{{map_location.region.name ? map_location.region.name : map_location.region.region}}</option>
                </select>
              </td>
            </tr>
          </table>
        </div>
        <br /><br /><br />
        <div id="map" :style="'border: 1px solid; width: 100%; height: '+(height - 360)+'px;'"></div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import roads from '../../services/roads';
import bot from '../../services/bot';
import store from '../../store'
import { Loader } from '@googlemaps/js-api-loader';

export default {
  name: 'Map',
  components: { },
  data: function () {
		return {
            map: "",
            maps: [],
            latitude: 0,
            longitude: 0,
            height: 1000,
            region: '',
            type: 'sections',
            rubros: [
              {code: 'walksides', name: 'Banquetas'},
              {code: 'ways', name: 'Calles'},
              {code: 'lamps', name: 'Luminarias'},
              {code: 'trees', name: 'Arboles'},
              {code: 'waters', name: 'Agua Potable'},
              {code: 'sewers', name: 'Alcantarillado'}
            ]
        }
  },
  mounted: async function() {
    this.loading();

    this.latitude = localStorage.getItem('latitude')*1;
    this.longitude = localStorage.getItem('longitude')*1;

    let response = await roads.map("sections");

    if(response.type == "success"){
      this.map = response.data;
      this.region = this.map.region.region;
    }

    let response2 = await roads.getMaps("sections");

    if(response2.type == "success"){
      this.maps = response2.data;
    }

    const apiOptions = {
			apiKey: "AIzaSyBjRSxI62T4CTN4Jl_R-1PJoIl2cAo2pAA"
		}

    this.height = window.screen.height;

		const loader = new Loader(apiOptions);

		loader.load().then(() => {
			const map = this.displayMap();
		});

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.users = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    displayMap: async function() {
			const mapOptions = {
				center: { lat: this.latitude, lng: this.longitude },
				zoom: 15,
        mapTypeId: 'satellite',
        mapId: "434879d77db0d24b"
			};

			this.loading();

      let response = await roads.map("sections");

      if(response.type == "success"){
        this.map = response.data;
      }

      const mapDiv = document.getElementById('map');
			const map = new google.maps.Map(mapDiv, mapOptions);

      const mapLayer = new google.maps.KmlLayer({
				url: this.map.kml_file, 
				map: map,
			});

      this.loaded();

			return map;
		},
    async changeLocation(){
      const mapOptions = {
				zoom: 15,
        mapTypeId: 'satellite'
			};

			const mapDiv = document.getElementById('map');
			const map = new google.maps.Map(mapDiv, mapOptions);

      this.loading();

      let response = await roads.regionTypeMap(this.region, this.type);

      if(response.type == "success"){
        this.map = response.data;
      }

      this.loaded();

      const mapLayer = new google.maps.KmlLayer({
				url: this.map.region.map.kml_file, 
				map: map,
			});

			return map;
    },
    async changeType(){
      const mapOptions = {
				zoom: 15,
        mapTypeId: 'satellite'
			};

			const mapDiv = document.getElementById('map');
			const map = new google.maps.Map(mapDiv, mapOptions);

      this.loading();

      let response = await roads.regionTypeMap(this.region, this.type);

      if(response.type == "success"){
        this.map = response.data;
      }

      this.loaded();

      const mapLayer = new google.maps.KmlLayer({
				url: this.map.region.map.kml_file, 
				map: map,
			});

			return map;
    },
  }
}
</script>
